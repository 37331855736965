import React from 'react';
import Banner from "../components/Banner";
import WhyChooseUs from "../components/WhyChooseUs";
import Fleet from "../components/Fleet";
import Services from "../components/Services";
import Reservation from "../components/Reservation";
import Footer from "../components/Footer";

const errPage = () => {
	return (
		<main className="container">
			<Banner />
			<WhyChooseUs />
			<Fleet />
			<Services />
			<Reservation />
			<Footer />
		</main>
	);
};

export default errPage;